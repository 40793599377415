import React from 'react'
import styled from 'styled-components'
import { Container, Section } from '../../global'

const Article = ({ data }) => {
	const { title, content, url, note, author } = data
	return (
		<Section id="page">
			<Container>
				<SArticle>
					<Title>
						<h4 dangerouslySetInnerHTML={{ __html: title }}></h4>
					</Title>
					<Author>
						<h3 dangerouslySetInnerHTML={{ __html: author }}></h3>
					</Author>
					<Content>
						{content.map((item, index) => {
							return <p key={index} dangerouslySetInnerHTML={{ __html: item }}></p>
						})}
					</Content>
					<Note>
						{note &&
							note.map((item, index) => {
								return <p key={index} dangerouslySetInnerHTML={{ __html: item }}></p>
							})}
					</Note>
				</SArticle>
			</Container>
		</Section>
	)
}

export default Article

const SArticle = styled.article`
	margin-bottom: 100px;
	sup {
		margin-left: 2px;
		padding: 1px 2px;
		/* color: #000; */
		border: 1px solid #999999;
	}
	details > summary {
		list-style: none;
	}

	details summary::-webkit-details-marker {
		display: none;
	}
`
const Author = styled.article`
	h3 {
		color: #000;
		margin-bottom: 5rem;

		/* padding: 0; */
	}
`
const Note = styled.div`
	margin-top: 100px;
	a {
		text-decoration: underline;
		color: #181868;
	}

	p {
		padding: 0;
		margin: 0;
	}
`
const Title = styled.div`
	h4 {
		text-align: left;
		margin-bottom: 1rem;
		&::before,
		&:after {
			display: none;
		}
	}
`
const Content = styled.div`
	p {
		/* color: #000000 !important; */
	}
	h5 {
		color: #000;
	}
`
