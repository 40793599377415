import React from 'react'
import SEO from '../../components/common/seo.js'
import Layout from '../../components/common/layout'
import Navigation from '../../components/navigation/navigation'
import Header from '../../components/sections/header'
import { Container, Flex, Section } from '../../components/global'
import Article from '../../components/sections/news/Article.js'
import { articles } from '../../components/sections/news/data'
const ArticlePage = (props) => {
	const url = props.location.pathname.split('/')[2]
	const article = articles && articles.filter((item) => item.url === url)
	console.log('====================================')
	console.log(article[0].shortTitle)
	console.log('====================================')
	// console.log(path)
	return (
		<Layout>
			<SEO title={article[0].shortTitle} description={article[0].content[0]} />
			<Navigation />
			<Header />
			<Article data={article[0]} />
		</Layout>
	)
}

export default ArticlePage
